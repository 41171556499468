.statusPageContainer {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.statusPageCode {
    font-size: 5em;
    font-weight: bold;
}

.statusPageCode.nonNumber{
    font-size: 2em;
}

.statusPageIcon {
    width: 300px;
    height: 300px;
    margin-right: 30px;
}

.statusPageMessage {
    max-width: 500px;
    font-size: 1.5rem;
}

.helpLink {
    font-size: 1.2rem;
    text-decoration: underline;
}