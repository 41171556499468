.dndOptionItemDropzone:not(:first-child) {
  padding-top: 10px;
}

.dndOptionItem {
  position: relative;
  padding: 5px 10px;
  /* background-color: #e9ecef; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/** 
 * Allowed combinations:
 * 1. Removable.
 * 2. Sortable.
 * 3. Removable + sortable.
 * 4. Hidden.
 * 5. Removable + hidden.
 **/

/** 1. Removable. */

.dndOptionItem.removable {
  padding-right: 30px;
}

.dndOptionItemTimes {
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  color: rgb(122, 122, 122);
}

.dndOptionItemTimes:hover {
  color: rgb(51, 51, 51);
}

.dndOptionItemTimes::after {
  content: "";
  height: 80%;
  width: 1px;
  position: absolute;
  left: 0px;
  top: 10%;
  background: #ccc;
  pointer-events: none;
}

/** 2. Sortable. */

.dndOptionItem.sortable {
  padding-right: 30px;
}

.dndOptionItemSort {
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  color: rgb(122, 122, 122);
}

.dndOptionItemSort:hover {
  color: rgb(51, 51, 51);
}

.dndOptionItemSort::after {
  content: "";
  height: 80%;
  width: 1px;
  position: absolute;
  left: 0px;
  top: 10%;
  background: #ccc;
  pointer-events: none;
}

/** 3. Removable + sortable. */

.dndOptionItem.removable.sortable {
  padding-right: 60px;
}

.dndOptionItem.removable.sortable .dndOptionItemSort {
  right: 30px;
}

/** 4. Hidden. */

.dndOptionItem.hidden {
  padding-right: 30px;
}

.dndOptionItemHidden {
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  color: rgb(51, 51, 51);
}

.dndOptionItemHidden.dynamic {
  color: rgb(216, 216, 216);
  cursor: pointer;
}

.dndOptionItemHidden.dynamic:hover {
  color: rgb(78, 78, 78);
}

.dndOptionItemHidden.dynamic.selected {
  color: rgb(51, 51, 51);
}

.dndOptionItemHidden::after {
  content: "";
  height: 80%;
  width: 1px;
  position: absolute;
  left: 0px;
  top: 10%;
  background: #ccc;
  pointer-events: none;
}

/** 5. Removable + hidden. */

.dndOptionItem.removable.hidden {
  padding-right: 60px;
}

.dndOptionItem.removable.hidden .dndOptionItemHidden {
  right: 30px;
}
