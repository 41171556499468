.nptTable {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
}

.nptTableContainer {
  display: flex;
  flex-direction: column;
}

.nptTableContainer.haveGant .nptTableScrollArea :global(.ps__rail-y) {
  display: none;
}

.nptTableHeaderContainer {
  display: flex;
  flex-direction: row;
}

.nptTableBodyContainer {
  display: flex;
  flex-direction: row;
  flex: 0 1 100%;
  overflow: hidden;
}

.nptTableContainerColumn {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.nptTableContainerColumn:not(:last-child) {
  border-right: 1px solid #ccc;
}

.nptTableMain {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f5f5f5;
}

.nptTableToolbar {
  position: relative;
  background: #f5f5f5;
  white-space: nowrap;
}

.nptTableToolbarButtons {
  padding: 5px 5px;
  border-color: #ccc;
  border-bottom: 1px solid #ccc;
}

.nptTableToolbar .nptToolbarItem {
  margin: 5px;
}

.nptTableToolbar .nptToolbarItem,
.nptTableToolbar .nptToolbarItem :global(.input-group-prepend) button,
.nptTableToolbar .nptToolbarItemCheckbox {
  height: 31px;
}

.nptTableToolbar .nptToolbarItem :global(.input-group-prepend),
.nptTableToolbar .nptToolbarItem :global(.input-group-prepend) button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.nptTableToolbar .nptToolbarItemCheckbox {
  padding: 2px 2px;
  display: inline-flex !important;
  align-items: center;
}

.nptTableToolbar .nptToolbarItemCheckbox.disabled {
  background: #eee;
}

.nptTableToolbar .nptToolbarButton {
  padding: 0.175rem 0.3rem;
}

.nptTableToolbar .nptToolbarDropdownItem {
  cursor: pointer;
}

.nptTableToolbar.nptNotConfirmed {
  background: #bffba1 !important;
  border-color: #8bd666 !important;
}

.nptTableHeader {
  height: 100%;
  min-height: 25px;
  position: relative;
  background: #eee;
  background-clip: padding-box;
  background-image: -webkit-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: -o-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: linear-gradient(to bottom, #fafafa 0, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffededed', GradientType=0);
  background-repeat: repeat-x;
}

.nptTableHeader .nptTableCell {
  position: relative;
  padding: 5px;
  border: 1px solid #ccc;
  border-left: 0px;
  border-top: 0px;
  border-bottom-width: 2px;
  font-weight: bold;
  overflow: visible;
  word-break: break-word;
}

.nptTableHeader .nptTableCell:first-child {
  border-left: 0px;
}

.nptTableHeader .nptTableCell:last-child {
  border-right: 0px;
}

.nptTableHeader .nptTableCell.sortable {
  cursor: pointer;
}

.nptTableHeader .nptTableCell.sortable.sorted {
  padding-top: 3px;
  border-top: 2px solid #0088ce;
  color: #0088ce !important;
}

.nptTableHeader .nptTableCell.sortable.sorted .order {
  margin-left: 5px;
}

.nptTableHeader .nptFilter {
}

.nptTableHeader .nptFilter.active {
  color: #90ee90;
}

.nptFilterDropdown {
  min-width: 350px !important;
  position: absolute;
  z-index: 1100 !important;
}

.nptFilterDropdown .nptFilterDropdownResizer {
  width: 7px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: -2px;
  cursor: e-resize;
}

.nptFilterDropdown .nptFilterDropdownResizer.left {
  right: auto;
  left: -2px;
}

.nptFilterDropdown .nptFilterTab {
  display: flex;
  justify-content: center;
  padding: 2px 5px;
  cursor: pointer;
}

.nptFilterDropdown .nptFilterTab:not(.active) {
  background-color: #f1f1f1;
  background-image: -webkit-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: -o-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: linear-gradient(to bottom, #fafafa 0, #ededed 100%);
  border: 1px solid #bbb;
  border-top: none;
  color: #4d5258;
}

.nptFilterDropdown .nptFilterTab:not(.active):hover {
  background: rgba(0, 0, 0, 0.1);
}

.nptFilterDropdown .nptFilterTabContent {
  display: none;
}

.nptFilterDropdown .nptFilterTabContent.active {
  display: flex;
}

.nptFilterDropdown .nptFilterValues {
  padding: 10px;
}

.nptFilterDropdown .nptFilterEqualitySelector {
  height: 100%;
  border: none;
  border-left: 2px solid transparent;
  border-right: 3px solid transparent;
  background: none;
  outline: none;
  font-size: 1.1rem;
  cursor: pointer;
}

.nptFilterDropdown .nptFilterOptionsToggler {
  position: relative;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.nptFilterDropdown .nptFilterOptionsToggler:hover {
  background: rgba(0, 0, 0, 0.1);
}

.nptFilterDropdown .nptFilterOptionsToggler .toggleIcon {
  margin-right: 10px;
  transition-duration: 0.3s;
}

.nptFilterDropdown .nptFilterOptionsToggler.toggled .toggleIcon {
  transform: rotate(90deg);
}

.nptFilterDropdown .nptFilterOptionsToggler .loadingIcon {
  display: none;
  position: absolute;
  right: 10px;
}

.nptFilterDropdown .nptFilterOptionsToggler.loading .loadingIcon {
  display: block;
}

.nptFilterDropdown .nptFilterOptionsBody {
  max-height: 0px;
  overflow: auto;
  transition-duration: 0.3s;
  z-index: 1;
}

.nptFilterDropdown .nptFilterOptionsBody.toggled {
  max-height: 250px;
}

.nptFilterDropdown .nptFilterOptionsItem {
  min-height: 40px;
  padding: 0px 10px;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.nptFilterDropdown .nptFilterOptionsItem input {
  cursor: pointer;
}

.nptFilterDropdown .nptFilterOptionsItem:hover {
  background: rgba(0, 0, 0, 0.1);
}

.nptFilterDropdown .nptFilterOptionsItem .nptFilterOptionsItemSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

.nptFilterDropdown .nptFilterOptionsItem .nptFilterOptionsItemLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* white-space: nowrap; */
  overflow: hidden;
}

.nptFilterDropdown .nptFilterOptionsItem .nptFilterOptionsItemLabel span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.nptFilterDropdown .nptFilterDropdownFooter {
  margin: 10px 0px;
}

.nptFilterEmptyCell {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.nptFilterEmptyCell:not(:first-child) {
  border-top: 1px solid #ccc;
}

.nptTableHeader .nptTableHeaderResizer {
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0px;
  cursor: e-resize;
}

.nptTableHeader .nptTableHeaderResizer.left {
  left: 0px;
}

.nptTableHeader .nptTableHeaderResizer.right {
  width: 6px;
  right: -1px;
}

.nptTableBody {
  overflow: visible !important;
}

.nptTableBody:focus {
  outline: none;
}

.nptTableCell {
  flex-basis: 50px;
  overflow: auto;
}

.nptTableCell:not(:last-child) {
  border-right: 1px solid #ccc;
}

.nptTableSelectCell {
  justify-content: center;
  align-items: center;
}

.nptTableSelectCell input {
  cursor: pointer;
}

.nptTableBooleanCell {
  pointer-events: none;
}

.nptTableRow {
  border-bottom: 1px solid #ccc;
  background: #f5f5f5;
}

.nptTableRow.changed {
  background: #cefccd;
}

.nptTableRow.odd {
  background: white;
}

.nptTableRow.odd.changed {
  background: #d6ffd5;
}

.nptTableRow:hover {
  background: #def3ff;
  border-bottom-color: #7dc3e8;
}

.nptTableRow.changed:hover {
  background: #b1f7b0;
  border-bottom-color: #6bdb69;
}

*:not(.canDrop) > .draggable .nptTableRow:hover,
*:not(.canDrop) > .draggable .nptTableCellData:hover {
  background: #fffbd2;
  border-bottom-color: #e9da86;
  cursor: grab;
}

.canDrop .nptTableRow,
.canDrop .nptTableCellData {
  background: #f5dd8d !important;
  border-bottom-color: #e7d35e !important;
}

.nptTableRow.unavailable {
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
  text-align: center;
  pointer-events: none;
}

.nptTablePagination {
  padding: 5px 0px;
  border: 1px solid #ccc;
  background: #f5f5f5;
}

.nptTablePagination :global(.form-group) {
  margin: 0px 5px;
}

.nptTablePagination :global(.form-group):first-child {
  margin-left: 0px;
}

.nptTablePagination :global(.form-group):last-child {
  margin-right: 0px;
}

.nptTablePagination :global(.form-group.group-separate) {
  margin: 0px 2px;
}

.nptTablePagination :global(.form-group.group-separate) > span,
.nptTablePagination :global(.form-group.group-separate) > div {
  margin: 0px 3px;
}

.nptTablePaginationPanel :global(.input-group) {
  display: block;
  width: auto;
}

.nptTablePaginationPanel span {
  line-height: 25px;
}

.nptTablePaginationPanel input {
  height: 27px !important;
  text-align: right;
}

.nptTablePaginationInfo button {
  height: 27px;
  line-height: 0.85rem;
  font-size: 0.9rem;
}

.nptTablePaginationInfo span {
  display: inline-flex;
}

.nptTablePaginationItem {
  height: 27px;
  min-width: 30px;
  cursor: pointer;
  text-align: center;
  background-color: #f1f1f1;
  background-image: -webkit-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: -o-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: linear-gradient(to bottom, #fafafa 0, #ededed 100%);
  border: 1px solid #bbb;
  color: #4d5258;
}

.nptTablePaginationItem:not(:last-of-type) {
  margin-right: -1px;
}

.nptTablePaginationItem:not(.active):not(.disabled):hover {
  background-color: #f1f1f1;
  background-image: none;
  border-color: #bbb;
  color: #00526e;
}

.nptTablePaginationItem:not(.active):not(.disabled):active {
  -webkit-box-shadow: inset 0 2px 8px rgba(3, 3, 3, 0.2);
  box-shadow: inset 0 2px 8px rgba(3, 3, 3, 0.2);
}

.nptTablePaginationItem.active {
  cursor: auto;
  color: white;
  background: rgb(79, 129, 189);
  border-color: rgb(53, 104, 165);
}

.nptTablePaginationItem.disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  background-image: -webkit-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: -o-linear-gradient(top, #fafafa 0, #ededed 100%);
  background-image: linear-gradient(to bottom, #fafafa 0, #ededed 100%);
  color: #9c9c9c;
  background-color: #fff;
  border-color: #ddd;
}

.nptGant.hideScrollbars :global(.ps__rail-x),
.nptGant.hideScrollbars :global(.ps__rail-y) {
  display: none;
}

.nptGantHeader {
  display: flex;
  flex-direction: column;
}

.nptGantScale {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
}

.nptGantScale:first-child {
  flex: 0 1 100%;
}

.nptGantScale:last-child {
  border-bottom: 2px solid #ccc;
}

.nptGantScaleCell {
  overflow: hidden;
  text-align: center;
}

.nptGantScaleCell:not(:last-child) {
  border-right: 1px solid #ccc;
}

.nptGantBody {
  overflow: visible !important;
}

.nptGantBody:focus {
  outline: none;
}

.nptGantRow {
  position: relative;
  border-bottom: 1px solid #ccc;
}

.nptGantItem {
  position: absolute;
  border: 1px solid #ccc;
  overflow: hidden;
}

.nptGantItem.editable {
  cursor: grab;
}

.nptGantItem.editable:active {
  cursor: grabbing;
}

.nptGantItemDash {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  left: 0px;
  top: 0px;
}

.nptGantItemResizer {
  position: absolute;
  height: 100%;
  width: 10px;
  max-width: 35%;
  top: 0px;
  cursor: e-resize;
}

.nptGantItemResizer:hover {
  background: rgba(245, 228, 79, 0.7);
}

.nptGantItemResizer.left {
  left: 0px;
}

.nptGantItemResizer.right {
  right: 0px;
}

.nptGantBodyScale {
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  background: #f5f5f5;
}

.nptGantLegendContainer {
  /* overflow: hidden; */
  text-align: center;
  /* overflow-y: auto; */
  border: 1px solid rgb(221, 221, 221);
}

.nptGantLegend {
  height: 100%;
  text-align: center;
  padding: 5px 10px;
  overflow-y: auto;
  background: #eaeaea;
  background: linear-gradient(to right, #f3f3f3, #e5e5e5);
}

.nptGantLegend div {
  margin-bottom: 2px;
  text-align: left;
}

.nptGantLegend .nptGantLegendGroup {
  float: left;
  min-width: 190px;
  margin-right: 10px;
}

.nptGantLegend .img {
  width: 40px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
  border: 1px solid;
}

/*******************************
 * Chrome, Opera, Safari scrollbar
 *******************************/

.nptTableCell::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

.nptTableCell::-webkit-scrollbar-track {
  background: #f1f1f1;
}
