.container {
    position: fixed; 
    padding-top: 1.2rem;
    padding-right: 1.5rem;
    padding-bottom: 1.2rem;
    padding-left: 1.5rem;
    z-index: 10000
}
.topRightPosition {
    top: 0;
    right: 0;
    text-align: "right"
}
.topLeftPosition {
    top: 0;
    left: 0
}
.bottomRightPosition {
    bottom: 0;
    right: 0;
    text-align: "right"
}
.bottomLeftPosition {
    bottom: 0;
    left: 0
}
 