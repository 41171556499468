.inset {
    width: 100%;
    height: calc(100vh - 200px);
}

.toolbar {
    border: 1px solid #ccc;
    background: #eee;
    padding: 5px 10px 5px 300px;
    position: relative;
}

.toolbar .toolbarTabs {
    position: absolute;
    left: 20px;
    width: 260px;
}

.toolbar .toolbarTabs :global(.btn):not(:last-child) {
    border-right: none;
}

.toolbar .toolbarTabs :global(.btn.active) {
    background: white!important;
    border-bottom: 3px solid #278cff!important;
    padding-bottom: 1px;
    pointer-events: none;
}

.toolbar form {
    flex-direction: row-reverse;
}

.toolbar :global(.form-group) {
    margin: 5px!important;
}

.toolbar :global(.form-group)::after {
    height: 100%;
    width: 1px;
    content: ' ';
    border-right: 1px solid #ddd;
}

.toolbar :global(.btn) {
    -webkit-box-shadow: 0 2px 3px rgba(3, 3, 3, .1);
    box-shadow: 0 2px 3px rgba(3, 3, 3, .1);
    transition: none;
}

.toolbar :global(.btn):active {
    -webkit-box-shadow: inset 0 2px 8px rgba(3, 3, 3, .2);
    box-shadow: inset 0 2px 8px rgba(3, 3, 3, .2);
}

.toolbar :global(.btn).disabled {
    pointer-events: none;
}

.toolbar :global(.input-group-btn) :global(.btn) {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tree {
    position: relative;
    float: left;
    height: 100%;
}

.tree h4 {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1px;
}

.tree :global(.primary-badge) {
    position: absolute;
    right: 0px;
    top: 0px;
    background: #eee;
    font-size: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 0.19rem 0px;
    margin-left: -5px;
}

.tree :global(.primary-badge):before {
    content: "";
    width: 11px;
    height: 0px;
    position: absolute;
    top: 0px;
    left: -11px;
    border-top: 1.625rem solid #ccc;
    border-left: 10px solid transparent;
}

.tree :global(.primary-badge):after {
    content: "";
    width: 10px;
    height: 0px;
    position: absolute;
    top: 0px;
    left: -10px;
    border-top: 1.625rem solid #eee;
    border-left: 10px solid transparent;
}

.tree :global(.primary-badge) input {
    position: relative;
    top: 0.1rem;
    margin: 0px 5px;
    padding: 2px;
    font-size: 0.7rem;
}

.info {
    display: flex;
    border-top: 1px solid #ccc;
    background: #eee;
}

.info h4 {
    font-size: 1.1rem;
    margin-bottom: 5px;
}

.details {
    position: relative;
    float: left;
    background: #f5f5f5;
    height: 100%;
    overflow: auto;
}

.details h4 {
    margin-bottom: 0px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    background: #eee;
}

.detailsContainer {
    padding: 2px 15px;
    margin: auto;
    border-top: none;
}

.files {
    max-width: 600px;
    width: 50%;
    min-width: 400px;
    margin: auto;
    padding: 20px;
}

.files :global(.Select) {
    z-index: 2;
}

.filedrop {
    margin-top: 10px;
    padding-top: 0.7rem;
}

.files .filedrop .filedropLabel {
    position: absolute;
    left: 25px;
    top: -0.15rem;
    background: white;
    display: inline;
    z-index: 0;
}

.files .filedrop>div[aria-disabled] {
    padding: 5px;
}

.files.disabled .filedrop, .files.disabled .filedrop select {
    color: #aaa;
    cursor: not-allowed!important;
}

.files.disabled .filedrop div {
    pointer-events: none;
}

.files.disabled .filedrop>div[aria-disabled] {
    border-color: #ccc!important;
}

.fileuploaded {
    border: 2px solid rgb(102, 102, 102);
    padding: 10px;
}

.fileuploaded i {
    color: green;
}

.filedrop {
    position: relative;
}

.filedropEncode {
    position: absolute;
    top: 0.8rem;
    right: 2px;
}

.filedropEncode select {
    border-color: rgb(160, 160, 160);
    border-width: 1px;
    border-top: none;
    border-right: none;
    border-top-right-radius: 5px;
    background: #f2f4f5;
    color: #4b4b4b;
}

.filedropEncode select:disabled {
    border-color: rgb(192, 192, 192);
    color: #7c7c7c;
    background: #f7f7f7;
}

.files.disabled .filedropEncode select {
    border-color: #ccc;
    background: #fbfcfc;
}