.sidebar{
    min-width:20rem
}

.wrapper{
    /*For chrome(and others I guess, excluding mozilla) we should set perspective for parent nodes*/
    -webkit-perspective:1500px;  
    perspective:1500px;
    flex: 0 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column; 
    align-items: stretch;  
}
.content{
    /*For mozilla we should set perspective for target node,where transform is being applied*/
    -moz-perspective:1500px;
}


.sidebar {      
    flex-direction: column; 
}
 

.animationProps{
    -moz-animation-duration:0.4s;
            animation-duration:0.4s;   
    -moz-animation-fill-mode: forwards;   
            animation-fill-mode: forwards;
    -moz-animation-timing-function :cubic-bezier(0.945, 0.020, 0.270, 0.665);
            animation-timing-function :cubic-bezier(0.945, 0.020, 0.270, 0.665);
}

.openMinimized{
    composes: animationProps;
    -moz-animation-name:open-minimized-sidebar-anim  ;    
    animation-name:open-minimized-sidebar-anim  ;      
}

.openClosed{
    composes: animationProps;
    -moz-animation-name:open-closed-sidebar-anim;
    animation-name:open-closed-sidebar-anim  ;
 }

.minimize{ 
    composes: animationProps;
    -moz-animation-name:minimize-sidebar-anim;
    animation-name:minimize-sidebar-anim   ;
}
.close{ 
    composes: animationProps;
    -moz-animation-name:close-sidebar-anim;
     animation-name:close-sidebar-anim;
    
     
}
.closeInstant{ 
    -moz-transform: rotateY(100deg); 
         margin-left:-20rem; 
}


.openMinimizedItem{
    opacity: 1 !important; 
    transition: opacity 0.9s   ; 
} 

.minimizedItem{
    opacity:0 !important;
    white-space: nowrap;
    position: absolute; 
    /* z-index: -1; */
     transition: all 0.5s; 
} 

.minimizedSidebar{
    compose:sidebar;
    width: 3.5rem;
}
.openedSidebar{
    compose:sidebar;
    width:20rem;
    min-width: 20rem;
}

h6.header {
    padding: 1rem; 
    /* padding-bottom: 1.1rem    */
}

.headerClosed{ 
}

.sidebar.active > h6.header > a > svg {
    margin-right: 0.5rem;
}

.sidebarBody::-webkit-scrollbar-track {
    background: none;
}
.sidebarBody::-webkit-scrollbar {
    width: 3px;
    background: none;
}
.sidebarBody::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.6);
    border-radius: 5px;
}
.sidebarBody::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.8);
}

a.item {
    padding: 1rem;
    font-size: 1.1em;
    display: block;
    white-space: normal !important;
}

a.item:hover {
    background: #fff;
}

a.item.highlight {
    background: #fff;
}

.sidebar.active > a.item > i {
    margin-right: 0.5rem;
}

.contentWidth{ 
}

@-moz-keyframes close-sidebar-anim {   
    0% {         
         width: 20rem;
        -moz-transform: rotateY(0deg); 
         transform-origin: center left;                 
    }
     
    100%{
        -moz-transform: rotateY(100deg); 
         margin-left:-20rem;         
    }
  }

  @keyframes close-sidebar-anim {   
    0% {   
        width: 20rem;  
        transform: rotateY(0deg);  
        transform-origin: center left;                
    }
     
    100%{
        transform: rotateY(100deg);  
        margin-left:-20rem;    
    }
  }

  @-moz-keyframes minimize-sidebar-anim {  
    0% {
        width: 20rem;  
        white-space: nowrap;           
    } 
    100%{
        width: 3.5rem;         
        /* white-space: nowrap;  */
    }
    
  }

  @keyframes minimize-sidebar-anim {   
    0% {
        width: 20rem;  
        white-space: nowrap;           
    } 
    100%{
        width: 3.5rem;          
    }
    
  }

  

  @-moz-keyframes open-closed-sidebar-anim {      
    0%{
        -moz-transform: rotateY(100deg);                
        -moz-margin-left:-20rem;         
    }
    100% {
        -moz-width: 20rem;
        -moz-transform: rotateY(0deg); 
        -moz-transform-origin: center left;                 
    }
  }

  

  @keyframes open-closed-sidebar-anim {       
     
    0%{ 
        transform: rotateY(100deg);
        margin-left:-20rem;         
    }
    100% {
        width: 20rem; 
        transform: rotateY(0deg); 
        transform-origin: center left;                 
    }
  }

  @-moz-keyframes open-minimized-sidebar-anim {   
     
    0%{
        width: 4rem;         
        white-space: nowrap; 
    }
    99% { 
        width: 20rem; 
        white-space: nowrap;            
    }
    100%{
        width: 20rem; 
        white-space: normal; 
    }
  }

  @keyframes open-minimized-sidebar-anim {    
     
    0%{
        width: 4rem;         
        white-space: nowrap; 
    }
    99% { 
        width: 20rem; 
        white-space: nowrap;            
    }
    100%{
        width: 20rem; 
        white-space: normal; 
    }
  }

  @-moz-keyframes show-sidebar-item-anim {    
    from{
       opacity: 0;
    }
    to{ 
        opacity: 1;            
    }
  }

  @keyframes show-sidebar-item-anim {    
    from{
       opacity: 0;
    }
    to{ 
        opacity: 1;            
    }
  }

  @-moz-keyframes hide-sidebar-item-anim {    
    from{
       opacity: 1;
    }
    to{ 
        opacity: 0;            
    }
  }

  @keyframes hide-sidebar-item-anim {   
    from{
       opacity: 1;
    }
    to{ 
        opacity: 0;            
    }
  } 
 