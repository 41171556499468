.container{ 
    background-color: gray;
    border-radius: 26px;
    position: relative;
    cursor: pointer;
}
.round{ 
    background-color: #fff;
    border-radius: 20px;
    position: absolute;
    top: 2px;
    transition: 0.3s;
    box-shadow: rgba(50, 50, 50, 0.75) 0px 0px 3px 1px;
}
 .hiddenLabel{
    height:0;
    visibility:'hidden';
    display:'inline-block';
    z-index:-10
 }
 

