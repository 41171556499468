.autocomplete {
  z-index: 999;
  background: white;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 5%);
  width: 100%;
  max-height: 130px;
}

.item {
  cursor: pointer;
  transition: all 0.2s;
}
.item:hover {
  transition: all 0.2s;
  background: var(--secondary);
}

.itemActive {
  background: var(--secondary);
}
