.bulbSuccess {
  --bulb-color: #73a839;
  --bulb-half-color: #73a83990;
  --bulb-trans-color: #73a83900;
}
.bulbDanger {
  --bulb-color: #c71c22;
  --bulb-half-color: #c71c2290;
  --bulb-trans-color: #c71c2200;
}
.bulbWarning {
  --bulb-color: #ffee58;
  --bulb-half-color: #ffee58;
  --bulb-trans-color: #dd560000;
}
.bulbPrimary {
  --bulb-color: #2fa4e7;
  --bulb-half-color: #2fa4e790;
  --bulb-trans-color: #2fa4e700;
}
.bulbSecondary {
  --bulb-color: #e9ecef;
}

.bulb {
  position: relative;
  z-index: 100;
  display: inline-block;
  border-radius: 100%;

  background-color: var(--bulb-color);
}
.bulbSm {
  width: 10px;
  height: 10px;
}
.bulbMd {
  width: 20px;
  height: 20px;
}
.bulbLg {
  width: 30px;
  height: 30px;
}

.pulsing {
  box-shadow: 0 0 0 var(--bulb-color);
  animation: pulse 2s infinite, pulse 1s infinite;
  /* animation-delay: 0s, 1s; */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--bulb-half-color);
  }
  70% {
    box-shadow: 0 0 0 15px var(--bulb-trans-color);
  }
  100% {
    box-shadow: 0 0 0 0 var(--bulb-trans-color);
  }
}
