.timeFooter{
    display:flex;
    justify-content: center;
    padding: 0.3rem 0.5rem;
    background: rgb(233,236,239);
    background: linear-gradient(180deg, rgba(233,236,239,.4) 15%, rgba(255,255,255,.1) 45%, rgba(233,236,239,.4) 78%);
  
    -webkit-box-shadow: 0px 0px 12px 0px rgba(50, 50, 50, 0.53);
    -moz-box-shadow:    0px 0px 12px 0px rgba(50, 50, 50, 0.53);
    box-shadow:         0px 0px 12px 0px rgba(50, 50, 50, 0.53);     
} 

 .timeSegment{
    width:2.1rem !important;
    height: 3rem !important;
    padding: 0 0.4rem !important;
    -webkit-box-shadow: inset 0px 0px 5px -1px rgba(0, 0, 0, 0.64);
    -moz-box-shadow:    inset 0px 0px 5px -1px rgba(0, 0, 0, 0.64);
    box-shadow:         inset 0px 0px 5px -1px rgba(0, 0, 0, 0.64);
 }
 .millisecondsSegment{
    composes:timeSegment ;
    width:2.7rem !important;
 }
  
 .timeSegmentDevider{
     font-weight: bold;
     display:flex;
     flex-direction: column;
     justify-content: space-around;
     padding: 0 .2rem;
 }