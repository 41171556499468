/* .tabs > :global(.nav-link) {
    padding: 0.2rem;
    margin-left: 0.1rem;
    background-color: rgb(230, 230, 230);
}

.tabs > :global(.active) {
    font-weight: bold;
} */

.tabs{
    margin-bottom: .2rem ;
    margin-right: .4rem ;
    margin-left: .4rem ;
}