.dateTimePickerContainer {
  display: flex;
  font-size: 1rem;
  border-radius: 10px;
  overflow: hidden;
  /* width: 17rem; */
  -webkit-box-shadow: 0px 0px 14px 0px rgba(47, 164, 231, 1);
  -moz-box-shadow: 0px 0px 14px 0px rgba(47, 164, 231, 1);
  box-shadow: 0px 0px 14px 0px rgba(47, 164, 231, 1);
  background-color: white;
  position: absolute;
  z-index: 100;
  /* left: 0; */
}
