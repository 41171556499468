  .evenRow,
  .oddRow {
    border-bottom: 1px solid #e0e0e0;
    padding-right:0px !important; 
    background-color: #fff;
  }
  .oddRow {
    background: #ddd;
    
  }  
  .dragged{
    cursor: ew-resize;
  }
  .headerResizerSpan{
    cursor: ew-resize;
    min-width: 5px;    
  }    
  .headerRow{
    background-image: -webkit-linear-gradient(top,#fafafa 0,#ededed 100%);
    background-image: -o-linear-gradient(top,#fafafa 0,#ededed 100%);
    background-image: linear-gradient(to bottom,#fafafa 0,#ededed 100%); 
    
     text-transform: none !important; 
     border-bottom:1px solid rgba(0, 0, 0, 0.09);
  }
  .headerColumn:last-child{
    border-right:1px solid transparent;
  }
  .headerLabelSpan{
    padding-top:5px;
  }
  .emptyFiller{
    flex-grow:4
  }
  .headerColumn{ 
    padding-left: 0px;  
    vertical-align: center !important;
    display: flex;
    height: inherit;
    border-right:1px solid rgba(0, 0, 0, 0.09);
    text-transform: none !important;
  }
  .headerColumn,.bodyColumn  { 
    margin-left: 0px !important; 
    line-height: normal;
    position: relative; 
    box-shadow: inset 0 0 0 0 transparent;
    white-space: pre-line !important;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0px !important;
  }
  .NoDataRow{
    text-align: center; 
    font-weight: bold; 
    padding:0.45rem;
    border-bottom:1px solid rgba(0, 0, 0, 0.09);
    border-top:1px solid rgba(0, 0, 0, 0.09); 
  }
  .bodyColumn{ 
    border-right:1px solid rgba(0, 0, 0, 0.09);
    border-bottom:1px solid rgba(0, 0, 0, 0.09);
    height:100%;
    overflow-y:auto !important;
  }
  .bodyColumn::-webkit-scrollbar-track {
    background: none;
}
.bodyColumn::-webkit-scrollbar {
    width: 3px;
    background: none;
}
.bodyColumn::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.6);
    border-radius: 5px;
}
.bodyColumn::-webkit-scrollbar-thumb:hover {
    background-color: rgba(100, 100, 100, 0.8);
}

  .bodyColumn:last-child{
    border-right:1px solid transparent;
  }
  .grid{
    border-bottom: 1px solid #e0e0e0;
  }
  .check{
    margin-right: 5px
  }
  .headerCheck{ 
    margin-right: 5px; 
  }
  .headerLabel{
    padding-left: 5px; 
  }
  .GridStyle{
    padding-right:0.1rem;
  } 

  .nptDoubleBtnLeft{
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
  }

  .nptDoubleBtnRight{
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
  }
  .nptTablePaginationItem{
    height: 2rem;
    min-width: 1.5rem;
    cursor: pointer;
    text-align: center;
    background-color: #f1f1f1;
     }
  .nptTablePaginationItem:not(:last-of-type){
    margin-right: -1px;
  }

  .nptTablePaginationItem:not(.active):not(.disabled):hover{
    background-color: #f1f1f1 !important;
    background-image: none !important;
    border-color: #bbb !important;
    color: #00526e !important;
  }

  .nptTablePaginationItem:not(.active):not(.disabled):active{
    -webkit-box-shadow: inset 0 2px 8px rgba(3,3,3,.2);
    box-shadow: inset 0 2px 8px rgba(3,3,3,.2);
  }

  .nptTablePaginationItem.active{
    cursor: auto;
    color: white;
   }

  .nptTablePaginationItem.disabled{
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
    background-image: -webkit-linear-gradient(top,#fafafa 0,#ededed 100%);
    background-image: -o-linear-gradient(top,#fafafa 0,#ededed 100%);
    background-image: linear-gradient(to bottom,#fafafa 0,#ededed 100%);
    color: #9c9c9c;
    background-color: #fff;
    border-color: #ddd;
  }


  .nptTablePaginationPanel{
    padding: 0 5px  ; 
  }

  .nptTablePaginationPanel .input-group{
    display: block;
    width: auto;
  }

  .nptTablePaginationPanel span{
    line-height: 2rem;
  }

  .nptTablePaginationInfo span{
    line-height: 2rem;
  }

  .nptTablePaginationPanel input{
    height:2rem;
    text-align: right;
  }

  .nptTablePaginationInfo {
    padding:0 5px;
  }

  .nptTablePaginationInfo button{
    height: 2rem;
    line-height: 20px;
  }
  .nptTablePaginationInfo button{
    background: #eee;
    border-color: #ccc;
  }
  .nptTablePagination{
    height: 2.7rem;
    padding: 5px 0px;
    border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
        font-weight: bold;
    /* border: 1px solid #ccc; */
  }
  .nptTablePaginationFormGroup{
    margin: 2px 5px;
  }
  .nptTablePaginationFormGroup button{
    width:1.8rem !important;
  }
  .nptTablePaginationFormGroup:first-child{
    margin-left: 0px;
    
  }
  .nptTablePaginationFormGroup button:first-child{
    margin-right: .1rem;
  }
  .nptTablePaginationFormGroup:last-child{
    margin-right: 0px;
  }
  .nptTablePaginationFormGroupGroupSeparate{
    margin: 2px 2px;
  }
  .nptTablePagination .form-group.group-separate > span,
  .nptTablePagination .form-group.group-separate > div{
    margin: 0px 3px;
  }
  .nptTablePaginationGroupSeparateSpan{
    margin: 0px 3px;
  }
  .nptTablePagination,
  .nptTablePagination{
    background: #f5f5f5;
    /* border-color: #ccc; */
  }
  .TableContainer{
    border-right:1px solid rgba(0, 0, 0, 0.09);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
 

  .DownloadColumn{
    display: flex;
    color: #2FA4E7;
    justify-content: center;
  }
  .DownloadColumn div{
    cursor: pointer;
  }

  .filter,.interactive{
    cursor:pointer;
  } 
  .interactive{
    text-decoration: underline;
    color:#2FA4E7;
  }
   
  .traceLevel,.debugLevel,.warnLevel,.errorLevel,.fatalLevel,.infoLevel{
    color:white; 
    border-radius: 4px;
    font-weight: bold; 
  }
  .traceLevel{ 
    background: lightskyblue;     
  }
  .infoLevel{ 
    background: blue;     
  }
  .debugLevel{
    background: green;  
  } 
  .warnLevel{ 
    background: orange;    
  }
  .errorLevel{
    background: red;   
  }
  .fatalLevel{
    background: crimson;  
  }
  .disabledItem{
    cursor:not-allowed;
  }
  /* #04519b */
  /* color:yellow; */