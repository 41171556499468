.element {
    display: flex;
    flex-direction: column;
    overflow: hidden!important;
}
.element.expanded {
    flex-grow: 1!important;
}
.element.collapsed {
    flex: 0!important;
}

.splitter {
    display: flex;
    align-items: center;
    background-color: lightgrey;
    transition-duration: 0.35s!important;
    opacity: 1;
}
.splitter.disabled {
    pointer-events: none;
    opacity: 0;
}
.disabled {
    pointer-events: none;
    opacity: 0 !important;
}
.container:global(.vertical)>.splitter {
    width: 0.35rem;
    flex-direction: row;
}

.container:global(.horizontal)>.splitter {
    height: 0.35rem;
    flex-direction: column;
}

.grabber {
    display: flex;
    position: relative;
    pointer-events: auto;
    background: var(--light);
    
    z-index: 10;
}
.wrapper{
    position: absolute;
}
 
.grabber:global(.horizontal) .wrapper{ 
    left: 50%;
    top: -19px;
     
}
.grabber:global(.vertical) .wrapper{ 
    top: 50%;
    display: flex;
    left: -22px;
    
}
.grabber:global(.vertical) {
/* .container:global(.vertical)>.splitter .grabber { */
    width: .2rem;
    /* left: -21px; */
    flex-direction: row;
    height:100%
}

.grabber:global(.horizontal) {
/* .container:global(.horizontal)>.splitter .grabber { */
    height: .5rem;
    top:-.25rem;
    /* top: -21px; */
    flex-direction: column;
}
.grabber:hover{
    background: var(--secondary);
}
 

.bar {
    position: relative;
    background-color: black;
}

.grabber:global(.vertical) .bar {
/* .container:global(.vertical)>.splitter .bar { */
    min-width: 0.35rem;
    max-width: 0.35rem;
    height: 34px;
    width:.9rem;
}

.grabber:global(.horizontal) .bar {
/* .container:global(.horizontal)>.splitter .bar { */
    min-height: 0.35rem;
    max-height: 0.35rem;
    width: 34px;
    height:.5rem;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: slategray;
    border: 1px solid black;
    color: white;
    transition-property: opacity;
    transition-duration: 300ms;
    cursor: pointer;
    /* pointer-events: all; */
    opacity: 0;
}

.grabber .wrapper:hover .arrow:not(.disabled)  {
/* .grabber:hover .arrow:not(.disabled) { */
    pointer-events: all;
    opacity: 0.5;
    cursor: pointer;
}

.grabber:global(.vertical)  .arrow {
/* .container:global(.vertical)>.splitter .arrow { */
    width: 20px;
}

.grabber:global(.horizontal)  .arrow {
/* .container:global(.horizontal)>.splitter .arrow { */
    height: 20px;
}

.grabber:global(.horizontal)  .arrow svg {
/* .container:global(.horizontal)>.splitter .arrow svg { */
    transform: rotate(90deg);
}

 .leftArrowVertical {
/* .container:global(.vertical)>.splitter .leftArrow { */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

  .rightArrowVertical {
/* .container:global(.vertical)>.splitter .rightArrow { */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

 .leftArrowHorizontal {
/* .container:global(.horizontal)>.splitter .leftArrow { */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rightArrowHorizontal {
/* .container:global(.horizontal)>.splitter .rightArrow { */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
/* .grabber:global(.vertical)   .leftArrow { 
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.grabber:global(.vertical)  .rightArrow { 
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.grabber:global(.horizontal)  .leftArrow { 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.grabber:global(.horizontal) .rightArrow { 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
} */