.arrow{
  color:white;
    cursor:pointer    
}

.arrowLeft{    
    border-right: 2.4em solid #ccc;
    left: 1.5rem;
}
.arrowLeft:hover{
    border-right-color: #06c;
}

.arrowRight{
    border-left: 2.4em solid #ccc;
    right: 1.5rem;
}
.arrowRight:hover{
    border-left-color: #06c;
} 

.calendarHeader{
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9ecef !important;
    background-color: #2FA4E7 !important;
    padding: 0.5rem 0px;
}

.calendarGrid {
  display: grid;
  grid-template: repeat(7, 2.4rem) / repeat(7, 2.1rem);
}

.calendarMonth {
    font-weight: 500;
    font-size: 5em;
    color: #06c;
    text-align: center;
    padding: 0.5em 0.25em;
    word-spacing: 5px;
    user-select: none;
}

 .calendarCell{
    text-align: center;
    align-self: center;
    letter-spacing: 0.1rem;
    padding: 0.6em 0.25em;
    user-select: none;

 }

 .calendarDay{
  background-color: #e9ecef !important;
    font-weight: bold;
    font-size: 0.7em;
    color: #2FA4E7;
    border-top: 2px solid white;
 }

  
 .calendarDayRightBorder
 {
    border-right: 2px solid #06c;
 }
  


 .calendarDate{ 
    font-size: 0.7em;
    cursor: pointer; 
    transition: all .2s ease-out;
     
 }
 .calendarDate:hover {
    color: #06c;
    background: rgba(0, 102, 204, 0.075);
    border-radius: 30px;
  }

  .todayCalendarDate{ 
    font-size: 0.7em;
    position: relative; 
    cursor:pointer;
    border-radius:30px;
    background-color: #b3e5fc !important;
    color:white;
    font-weight: bold; 
  }
  .todayCalendarDate::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    
  }
  .highlitedCalendarDate{ 
    font-size: 0.7em;
    position: relative; 
    border-radius:30px;
    background-color: #2FA4E7 !important;
    color:white;
    font-weight: bold;
    -webkit-box-shadow:  0px 0px 9px 0px rgba(50, 50, 50, 0.53);
    -moz-box-shadow:      0px 0px 9px 0px rgba(50, 50, 50, 0.53);
    box-shadow:    0px 0px 9px 0px rgba(50, 50, 50, 0.53);
  }
  .highlitedCalendarDate::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    
  }

  /* .todayCalendarDate{
    color: #06c !important;
    background: transparent !important;     
  }

  .todayCalendarDate::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0.75em solid #06c;
    border-left: 0.75em solid transparent;
    border-top: 0.75em solid transparent;
  }

  .todayCalendarDate:hover {
    color: #06c !important;
    background: rgba(0, 102, 204, 0.075) !important;
  } */

  