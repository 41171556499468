
.loader{
    position:relative;
     
    /* transform: translate(50%,50%); */
    width:50px;
    height:50px;
    box-sizing:border-box;
    background: transparent;
}

.loader .face{
    position:absolute;
    border:2px solid var(--secondary);
    
}

.loader .face.face1{
    top:0;
    left:0;
    right:0;
    bottom:0;
    /* background: #090909; */
    background: white ;
    /* box-shadow:0 0 5px var(--gray); */
    border-radius: 50%;
    border-left: 2px solid #03a9f4 ;
    border-top: 2px solid #03a9f4;
    animation:animate 3s linear infinite;
}

.loader .face.face2{
    top:15px;
    left:15px;
    right:15px;
    bottom:15px;
    background: var(--secondary);
    /* box-shadow:0 0 10px rgba(0,0,0,1); */
    border-radius: 50%;
    border-right: 2px solid var(--warning);
    border-bottom: 2px solid var(--warning);
    animation:animate 3s reverse linear infinite;
}

.loader .face .circle{
    position:absolute;
    top:calc( 50% - 1px );
    left:50%;
    width: 50%;
    height: 4px;
    /* background: #fff; */
    transform-origin: left;
}

.loader .face .circle:before{
    content:'';
    position:absolute;
    width:6px;
    height:6px;
    border-radius:50%;
    background: #fff;
    top:-5px;
    right:-5px;
}

.loader .face.face1 .circle:before{
    background: #03a9f4;
    box-shadow: 0 0 5px #03a9f4,
                  /* 0 0 10px #03a9f4,
                  0 0 15px #03a9f4, */
                  /* 0 0 20px #03a9f4, */
                  /* 0 0 25px #03a9f4, */
                  0 0 0 1px rgba(3,169,244,.1);
   
}
.loader .face.face2 .circle:before{
    
    background: var(--warning);
    box-shadow: 0 0 5px var(--warning),
                  /* 0 0 10px var(--warning),
                  0 0 15px var(--warning), */
                  /* 0 0 20px var(--warning), */
                  /* 0 0 25px var(--warning), */
                  0 0 0 1px rgba(255,255,0,.1);
}

.loader .face.face1 .circle{
   transform:rotate(-45deg);
}

.loader .face.face2 .circle{
   transform:rotate(-45deg);
}


@keyframes animate{
    0%{
        transform:rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}