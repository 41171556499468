/*******************************
 * Npt-resizer style
 *******************************/
 .npt-resizer {
    width: 3rem;
    padding: 0.5rem 0.25rem 0.75rem;
    border-radius: 1.5rem;
    border: 1px solid #ccc;
    opacity: 0.7;
    z-index: 102;
}

.npt-resizer:hover {
    opacity: 1;
    transition-duration: 0.05s;
}

.npt-resizer-fixed {
    left: 0px!important;
    top: 0px!important;
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 1rem!important;
    padding-top: 0.25rem!important;
    padding-bottom: 0.5rem!important;
}

.npt-resizer-button .btn {
    border-radius: 1rem!important;
    cursor: pointer;
    border: none;
}

.npt-resizer-button .btn i {
    font-size: 1rem;
}

.npt-resizer-breaker {
    margin-left: -0.25rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.npt-resizer-input {
    position: relative;
    padding: 0.15rem;
}

.npt-resizer-input input,
.npt-resizer-input .input-group-text {
    font-size: 0.6rem;
}

.npt-resizer-input .input-group-text {
    padding: 0;
}

/*Full screen*/
.fs-container{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255,255,255);
    overflow:auto;
    z-index: 100;
}

.pan-zone{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.1);
    z-index: 1;
}