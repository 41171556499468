.addButton {
    color: "green";
}

.removeButton{
    color: "red";
}

.button{ 
    top: 6px;
    left: 5px;
    cursor: pointer;
}

.cbSelectError .cb-select__control{
    border-color: 'var(--danger)';
}

:global(.cb-select__menu-portal) {
    z-index: 10;
}