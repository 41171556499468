.headerColumn {
  text-align: left;
  overflow-wrap: break-word;
  text-overflow: clip;
  white-space: normal;
  vertical-align: middle !important;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.cardTable tbody tr:nth-of-type(2n+1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.cardTable tbody th {
  font-weight: normal
}

.cardTable thead th {
  border-bottom: 1px solid #dee2e6;
}

.compoundTable {
  overflow-x: auto;
  max-height: 30rem;
}

:global(.npt-objectcard-toolbar-input) .compoundTable {
  overflow: auto;
  max-height: 400px;
}

.refTableDropzone {
  border-radius: 5px;
  transition-duration: 0.2s;
}

.header{
  position: sticky;
    top: 0;
}

.table {
  
}