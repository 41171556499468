.modalContainer {
  display: flex !important;
  flex-direction: column;
  overflow-y: auto;
}

.modalContainer> :global(.modal-dialog) {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
}

.modalContainer.modalFullscreen> :global(.modal-dialog) {
  width: 100% !important;
  max-width: 100% !important;
}

.modalContainer> :global(.modal-dialog)> :global(.modal-content) {
  width: 100%;
  overflow-y: auto;
}

.modalContainer> :global(.modal-dialog)> :global(.modal-content)> :global(.modal-body) {
  width: 100%;
  overflow-y: auto;
}