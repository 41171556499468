.nptNavigation {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}

.nptNavigationLink {
  width: 100%;
  min-height: 40px;
  padding: 10px 20px !important;
  color: #0a5c9d !important;
  white-space: normal;
}

.nptNavigationLink:global(.disabled) {
  pointer-events: none;
}

.nptNavigationLink:hover {
  background: rgba(0, 0, 0, 0.1);
}

.nptNavigationGroup {
  position: relative;
  flex: 1 1 auto;
  min-height: 80px;
  padding: 25px 15px 20px 15px;
  font-weight: 300;
  border: 1px solid transparent;
  text-align: center;
  transition-duration: 0s;
  cursor: pointer;
}

.nptNavigationSubGroup {
  width: 100%;
}

.markBorder {
  height: 36px !important;
  width: 36px !important;

  position: absolute;
  /* right: -17px; */
  border-radius: 50%;
  z-index: 99;
  border: 1px solid rgba(79, 195, 247, 0.9);
  background: transparent;
}
.markBorder:after {
  content: "";
  position: absolute;
  height: 40px !important;
  width: 40px !important;
  left: -3px;
  top: -3px;
  z-index: 99;
  border: 1px solid rgba(79, 195, 247, 0.9);
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6);
}
.markPulse {
  height: 30px !important;
  width: 30px !important;
  animation: pulsate 2s linear infinite;
  animation-iteration-count: infinite;
  position: absolute;
  padding-top: 0.1rem;
  left: 2px;
  color: white;
  top: 2px;
  border-radius: 50%;
  z-index: 100;
  background: rgb(129, 212, 250);
  background: radial-gradient(
    circle,
    rgba(25, 118, 210, 1) 0%,
    rgba(227, 242, 253, 1) 100%
  );
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(224, 224, 224, 0.9),
      0 0 0 0 rgba(224, 224, 224, 0.9);
  }
  50% {
    box-shadow: 0 0 0 20px rgba(66, 165, 245, 0),
      0 0 0 0 rgba(224, 224, 224, 0.9);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(66, 165, 245, 0),
      0 0 0 20px rgba(244, 67, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(66, 165, 245, 0), 0 0 0 20px rgba(244, 67, 54, 0);
  }
}
