
.pulse{
    
    animation: pulsate 2s linear infinite;
    animation-iteration-count: infinite;
    
    padding-top:.1rem;
  
    color:white;
    
    border-radius: 50%;
    z-index: 100;
     
}

.pulseYellow{
    background:rgb(255, 193, 7);
    background: radial-gradient(circle, rgba(255, 235, 59,1) 0%, rgb(255, 193, 7,.7) 100%);
}
.pulseRed{
    background:rgb(255, 23, 68);
    background: radial-gradient(circle, rgba(255, 23, 68,1) 0%, rgba(225, 52, 30,.1) 100%);
}
.pulseGreen{
    background:rgb(0, 188, 140);
    background: radial-gradient(circle, rgba(0, 188, 140,1) 0%, rgba(0, 188, 140,.1) 100%);
}
.pulsePurple{
    background:rgb(124, 77, 255);
    background: radial-gradient(circle, rgba(124, 77, 255,.7) 0%, rgba(124, 77, 255,.1) 100%);
}

@keyframes pulsate {
    0%{
        box-shadow:  0 0 0 0 rgba(255, 235, 59,.9), 0 0 0 0 rgba(224, 224, 224,.9);
        /* box-shadow:  0 0 0 0 rgba(224, 224, 224,.9), 0 0 0 0 rgba(224, 224, 224,.9); */
    }
    50%{
        box-shadow:  0 0 0 20px rgba(66, 165, 245,0),0 0 0 0 rgba(224, 224, 224,.9);
    }
    80%{
        box-shadow:  0 0 0 20px rgba(66, 165, 245,0),0 0 0 20px rgba(244, 67, 54,0);
    }
    100%{
        box-shadow:  0 0 0 0px rgba(66, 165, 245,0),0 0 0 20px rgba(244, 67, 54,0);
    }
}