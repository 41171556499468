.nptSitemapContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fafafa;
    display: flex;
    flex-direction: column;
}

.nptSitemapHeader {
    position: relative;
    height: 70px;
    min-height: 70px;
    border-bottom: 1px solid #ccc;
}

.nptSitemapSearch {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.nptSitemapSearch :global(.input-group) {
    width: 400px;
}

.nptSitemapClose {
    width: 70px;
    height: 60px;
    font-size: 42px;
}

.nptSitemapBody {
    flex: 1 1 100%;
    overflow: auto;
}

.nptSitemapBody svg {
    min-width: 100%;
    min-height: 100%;
    transition-duration: 0.75s;
}

.nptSitemapBody svg > g {
    transition-duration: 0.75s;
}

.nptSitemapNode {
    cursor: pointer;
}

.nptSitemapNode circle {
    fill: lightsteelblue;
    stroke: steelblue;
    stroke-width: 1.5px;
}

.nptSitemapNode.nptSitemapLinkNode circle {
    fill: #fff;
}

.nptSitemapNode text {
    font: 1.3rem sans-serif;
}

.nptSitemapNode.nptSitemapLinkNode text {
    text-decoration: underline;
    fill: steelblue;
}

.nptSitemapHiddenNode, .nptSitemapHiddenLink {
    display: none;
}

.nptSitemapLink {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
    z-index: -1;
}