.wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.close {
  top: 0;
  right: 50%;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
}
