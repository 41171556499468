.searchInputContainer {
    margin-bottom: 10px;
}

.searchInputContainer .searchInput {
    width: 100%;
    height: 44px;
    position: relative;
}

.searchInputContainer .searchInput .searchInputCard {
    width: 105%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: -2.5%;
    display: flex;
    align-items: center;
    border-radius: 25px;
    border-color: transparent;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    overflow: hidden;
}

.searchInputContainer .searchInput .searchInputCard:hover {
    box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
    border-color: rgba(223, 225, 229, 0);
}

.searchInputContainer .searchInput .searchInputCard input {
    width: 100%;
    height: 100%;
    padding-left: 2.5%;
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

.searchInputIcon {
    width: 50px !important;
    min-width: 50px;
    height: 100%;
    padding: 13px;
    padding-right: 15px;
    padding-left: 10px;
    cursor: pointer;
}

.searchInputStickIcon {
    width: 35px;
    height: 35px;
    margin: 0px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.searchInputStickIcon .searchInputIcon {
    padding: 8px;
}

.searchInputStickIcon:hover {
    background: rgba(100, 100, 100, 0.1);
}

.searchInputStickIcon.sticked {
    background: rgba(100, 100, 100, 0.2);
}

.searchClearIcon {
    width: 50px !important;
    min-width: 50px;
    height: 100%;
    padding: 13px;
    padding-right: 5px;
    cursor: pointer;
    position: relative;
}

.searchIconDelimeter {
    content: "";
    width: 1px;
    height: 60%;
    margin: 0px 2px;
    background: #ccc;
}

.searchCategoryContainer {
    border-bottom: 1px solid #ccc;
}

.searchCategory {
    padding: 7px 0px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}

.searchCategory.active {
    border-bottom-color: #2fa4e7;
}

.searchCategory:first-child {
    margin-left: 0px !important;
}

.searchInitialImageContainer {
    margin-top: 10px;
}

.searchInitialImage {
    min-height: 200px;
    max-height: 600px;
}

.searchResultContainer {
    overflow: auto;
}

.searchResultList {
    width: 100%;
    overflow: auto;
    padding: 0px 4%;
}

.searchResultTotal {
    padding: 0px 25px;
}

.searchResult {
    padding: 20px 25px;
    border-radius: 20px;
}

.searchResult:first-child {
    margin-top: 10px !important;
}

.searchResult:nth-child(even) {
    background: rgba(100, 100, 150, 0.05);
}

.searchResultLinkItem {
    display: inline-block;
}

.searchResultLinkItem:not(:last-child)::after {
    content: "/";
    margin: 0px 2px;
}

.searchResultDescription {
    position: relative;
}

.searchResultDescription:not(:last-child) {
    margin-bottom: 10px;
}

.searchResultDescription:not(:last-child)::after {
    content: "";
    width: 100px;
    max-width: 100%;
    height: 1px;
    position: absolute;
    left: 0px;
    bottom: -6px;
    background: #000;
}

.finder :global(.npt-finder-criteria-tree) {
    max-height: 150px;
    min-height: 150px;
    margin: 0px 4%;
}

/** Page padding */
body .searchInputContainer,
body .searchCategoryContainer,
body .searchResultContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchInputContainer .searchInput,
.searchAlertContainer {
    width: 100%;
    max-width: 750px;
}