@font-face {
  font-family: npt-icons;
  src: url(../fonts/npt-icons.eot?3ua1g2);
  src: url(../fonts/npt-icons.eot?3ua1g2#iefix) format("embedded-opentype"),
    url(../fonts/npt-icons.ttf?3ua1g2) format("truetype"),
    url(../fonts/npt-icons.woff?3ua1g2) format("woff"),
    url(../fonts/npt-icons.svg?3ua1g2#npt-icons) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

[class^="npt-i-"],
[class*=" npt-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "npt-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.npt-i-oek-icon:before {
  content: "\e973";
  color: #f37a1e;
}
.npt-i-eg-achr:before {
  content: "\e900";
}
.npt-i-eg-apv:before {
  content: "\e901";
}
.npt-i-eg-arrow:before {
  content: "\e902";
}
.npt-i-eg-battery:before {
  content: "\e903";
}
.npt-i-eg-breaker:before {
  content: "\e904";
}
.npt-i-eg-bus:before {
  content: "\e905";
}
.npt-i-eg-cabinet:before {
  content: "\e906";
}
.npt-i-eg-cable:before {
  content: "\e907";
}
.npt-i-eg-car:before {
  content: "\e908";
}
.npt-i-eg-car-breaker:before {
  content: "\e909";
}
.npt-i-eg-cb:before {
  content: "\e90a";
}
.npt-i-eg-cc:before {
  content: "\e90b";
}
.npt-i-eg-compensating-reactor:before {
  content: "\e90c";
}
.npt-i-eg-control-cooling-system:before {
  content: "\e90d";
}
.npt-i-eg-conv:before {
  content: "\e90e";
}
.npt-i-eg-cooling-system:before {
  content: "\e90f";
}
.npt-i-eg-copt:before {
  content: "\e910";
}
.npt-i-eg-ct:before {
  content: "\e911";
}
.npt-i-eg-current-transformer-phase:before {
  content: "\e912";
}
.npt-i-eg-current-transformer-winding:before {
  content: "\e913";
}
.npt-i-eg-dfz:before {
  content: "\e914";
}
.npt-i-eg-disconnector:before {
  content: "\e915";
}
.npt-i-eg-dz:before {
  content: "\e916";
}
.npt-i-eg-efn:before {
  content: "\e917";
}
.npt-i-eg-fan:before {
  content: "\e918";
}
.npt-i-eg-fgc:before {
  content: "\e919";
}
.npt-i-eg-fgc-alter:before {
  content: "\e91a";
}
.npt-i-eg-fun-null:before {
  content: "\e91b";
}
.npt-i-eg-gas-protection:before {
  content: "\e91c";
}
.npt-i-eg-generator:before {
  content: "\e91d";
}
.npt-i-eg-gil:before {
  content: "\e91e";
}
.npt-i-eg-high-frequency-choke:before {
  content: "\e91f";
}
.npt-i-eg-high-frequency-filter:before {
  content: "\e920";
}
.npt-i-eg-high-frequency-setting-element:before {
  content: "\e921";
}
.npt-i-eg-high-voltage-lead:before {
  content: "\e922";
}
.npt-i-eg-ifl:before {
  content: "\e923";
}
.npt-i-eg-mes:before {
  content: "\e924";
}
.npt-i-eg-motor:before {
  content: "\e925";
}
.npt-i-eg-mtz:before {
  content: "\e926";
}
.npt-i-eg-oil-level:before {
  content: "\e927";
}
.npt-i-eg-pmes:before {
  content: "\e928";
}
.npt-i-eg-pmes-alter .path1:before {
  content: "\e929";
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path5:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path6:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path7:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.npt-i-eg-pmes-alter .path8:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-pmes-alter .path9:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.npt-i-eg-potential-transformer-phase:before {
  content: "\e932";
}
.npt-i-eg-potential-transformer-winding:before {
  content: "\e933";
}
.npt-i-eg-power-transformer-end:before {
  content: "\e934";
}
.npt-i-eg-psh:before {
  content: "\e935";
}
.npt-i-eg-psh-ltc:before {
  content: "\e936";
}
.npt-i-eg-psh-phase:before {
  content: "\e937";
}
.npt-i-eg-reactor:before {
  content: "\e942";
}
.npt-i-eg-reactor-twin:before {
  content: "\e943";
}
.npt-i-eg-res:before {
  content: "\e944";
}
.npt-i-eg-roundknife:before {
  content: "\e945";
}
.npt-i-eg-rrc:before {
  content: "\e946";
}
.npt-i-eg-sar:before {
  content: "\e947";
}
.npt-i-eg-scr:before {
  content: "\e948";
}
.npt-i-eg-sk:before {
  content: "\e949";
}
.npt-i-eg-smc:before {
  content: "\e94a";
}
.npt-i-eg-tap-changer:before {
  content: "\e94b";
}
.npt-i-eg-tcf:before {
  content: "\e94c";
}
.npt-i-eg-tcr:before {
  content: "\e94d";
}
.npt-i-eg-technology-protection:before {
  content: "\e94e";
}
.npt-i-eg-temp-oil:before {
  content: "\e94f";
}
.npt-i-eg-tnznp:before {
  content: "\e950";
}
.npt-i-eg-to:before {
  content: "\e951";
}
.npt-i-eg-transformer-at3:before {
  content: "\e952";
}
.npt-i-eg-transformer-at3-ltc:before {
  content: "\e953";
}
.npt-i-eg-transformer-t1-ltc:before {
  content: "\e954";
}
.npt-i-eg-transformer-t2:before {
  content: "\e955";
}
.npt-i-eg-transformer-t2-b:before {
  content: "\e956";
}
.npt-i-eg-transformer-t2-b-ltc:before {
  content: "\e957";
}
.npt-i-eg-transformer-t2-ltc:before {
  content: "\e958";
}
.npt-i-eg-transformer-t3:before {
  content: "\e959";
}
.npt-i-eg-transformer-t3-itc:before {
  content: "\e95a";
}
.npt-i-eg-transformer-t4:before {
  content: "\e95b";
}
.npt-i-eg-transformer-t4-ltc:before {
  content: "\e95c";
}
.npt-i-eg-transformer-tank:before {
  content: "\e95d";
}
.npt-i-eg-transformer-th:before {
  content: "\e95e";
}
.npt-i-eg-tznp:before {
  content: "\e95f";
}
.npt-i-eg-water-gas-in-oil-control:before {
  content: "\e960";
}
.npt-i-eg-winding-temperature-control:before {
  content: "\e961";
}
.npt-i-eg-zmn:before {
  content: "\e962";
}
.npt-i-electrical-unit:before {
  content: "\e963";
}
.npt-i-enlarge-h:before {
  content: "\e964";
}
.npt-i-enlarge-v:before {
  content: "\e965";
}
.npt-i-level:before {
  content: "\e966";
}
.npt-i-logical-node:before {
  content: "\e967";
}
.npt-i-marker-default:before {
  content: "\e968";
}
.npt-i-marker-fix:before {
  content: "\e969";
}
.npt-i-marker-point:before {
  content: "\e96a";
}
.npt-i-marker-zero:before {
  content: "\e96b";
}
.npt-i-ps:before {
  content: "\e96c";
}
.npt-i-reduce-h:before {
  content: "\e96d";
}
.npt-i-reduce-v:before {
  content: "\e96e";
}
.npt-i-ru:before {
  content: "\e96f";
}
.npt-i-scheme:before {
  content: "\e970";
}
.npt-i-scheme-lan:before {
  content: "\e971";
}
.npt-i-vline:before {
  content: "\e972";
}
