.finder {
  padding: 0px;
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-top: none;
}

.innerContainer {
  flex: 1 1;
  border-top: 1px solid #ccc;
}

.toggleButton {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 8px;
}

.button {
  margin-left: 5px;
}

.advancedGrayscale {
  background: rgb(255, 255, 255);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding: 5px 10px;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 10;
}

.minimizeBtn {
  margin-top: -5px;
  padding-bottom: 5px;
}

.criteriaSeparator {
  margin: 7px -10px 7px 0px;
  padding-left: 5px;
  float: right;
}

/** Search string */
.search {
  position: relative;
  padding: 0px 10px;
  border-bottom: 1px solid #ccc;
}

.searchIcon {
  left: 1.15rem;
  color: #ccc;
}

.search .searchInput {
  padding-left: 1.75rem;
}

/** Confirm panel */
.confirmPanel {
  position: absolute;
  right: 10px;
  top: 100%;
  margin-top: -1px;
  padding: 8px 5px 5px;
  background: #ddd;
  border: 1px solid #777;
  border-top: none;
  z-index: 5;
}

.confirmPanelItem {
  padding: 0px 5px;
  font-size: 1.1rem;
  color: rgb(12, 126, 37);
  text-align: center;
  cursor: pointer;
}

.confirmPanelItem:not(:last-child) {
  border-right: 1px solid #72ce44;
}

.confirmPanelItem i {
  line-height: 30px;
  font-size: 25px;
}

.confirmPanelItem:hover {
  color: rgb(16, 187, 54);
}

.confirmPanelItem:active {
  color: rgb(14, 146, 43);
  -webkit-box-shadow: inset 0 2px 8px rgba(3, 3, 3, 0.1);
  box-shadow: inset 0 2px 8px rgba(3, 3, 3, 0.1);
}

.confirmPanel {
  background: #bffba1 !important;
  border-color: #8bd666 !important;
}

/** Criteria tree **/

.expandBar {
  flex: none !important;
}

.expandRow {
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.expandRow:hover {
  background: rgba(100, 100, 100, 0.1);
}

.expandRow:active {
  background: rgba(100, 100, 100, 0.2);
}

.criteriaTree {
  max-width: 100%;
  max-height: 50%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.criteriaTree.viewTypeEdit .tableRowOr,
.criteriaTree.viewTypeEdit .tableRowOr > span {
  height: 5px;
  line-height: 5px;
  font-size: 7px;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0);
}

/** Finder table **/

.table {
  flex: 0 1 100%;
  border: none;
  overflow-y: hidden;
}

.tableHeading span {
  font-weight: bold;
}

.tableBody {
  flex: 0 1 100%;
  overflow-y: auto;
}

.table div {
  text-align: center;
}

.table select,
.table .Select div,
.table .Select span {
  text-align: left;
}

.tableRowGroup,
.tableHeaderRow,
.tableRow,
.tableCellRelation,
.tableCellRelationRow {
  padding: 0px;
}

.tableHeaderRow {
  display: flex;
}

.tableRow {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tableRowGroup .tableRow:first-of-type {
  border-top: 1px solid #ddd;
}

.tableHeader {
  height: 100%;
  font-weight: bold;
}

.tableHeader:not(:last-of-type) {
  border-right: 1px solid #ddd;
}

.tableHeader:last-child {
  padding: 6px 10px;
}

.tableHeader .btnGroup {
  height: 100%;
  width: 100%;
}

.tableHeader .btnGroup .btn {
  height: 100%;
  padding: 2px 0px;
}

.tableHeader .btnGroup .btn i {
  margin-right: -3px;
}

.tableHeader .btnGroup .btn.active {
  background: #e7e7e7;
  pointer-events: none;
}

.tableHeader .btnGroup .btn:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.tableHeader .btnGroup .btn:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.tableHeader,
.tableCell {
  padding: 10px 10px;
  height: 100%;
}

.tableCell {
  display: flex;
}

.tableCell .tableCellSelection:nth-child(n + 4) {
  margin-top: 5px;
}

.tableCellRelation {
  display: flex;
  flex-direction: column;
}

.tableCellRelation .tableCellRelationRow {
  width: 100%;
  display: flex;
  border: 1px solid #ddd;
  border-right: none;
}

.tableCellRelation .tableCellRelationRow :global(.inputGroup-sm .form-control) {
  height: 24px;
}

.tableCellRelation .tableCellRelationRow .betweenRelationValue > span {
  min-width: 50px;
}

.tableRowOr {
  background: #f5f5f5;
  text-align: center;
  padding: 2px 10px;
  z-index: 10;
}

.tableRowButtons {
  padding: 5px;
}

.tableRowGroup:not(:last-child) .tableRowButtons {
  border-bottom: 1px solid #ddd;
}

.tableButton {
  cursor: pointer;
  margin-left: 5px;
}

/** Sidebar **/

.sideBarCard:not(:last-child) {
  display: block;
}

.sideBarCard:last-child {
  display: flex;
  flex: 0 1 100%;
}

.sideBarFragmentContainer {
  margin: 5px 0px 15px;
}

.sideBarFragment:not(:first-of-type) {
  margin-top: 5px;
}

.sideBarFragmentButton {
  width: 28px;
  margin-right: -1px;
}

.sideBarClass {
  margin: 5px 0px;
}

/** Combobox menu fix */
:global(.cb-finder__menu-portal) {
  z-index: 15 !important;
}
