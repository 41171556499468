.itemContainer {
    display: block;
    user-select:none;
}

.itemNode:not(:global(.tree-node-active)):hover {
    background: rgba(100, 100, 100, 0.08);
}

.unstyled {
    list-style-type: none;
}

.toggled {
    transform: rotate(90deg);
    transition: transform .5s ease;
}

.untoggled {
    transform: rotate(0deg);
    transition: transform .5s ease;
}

.nptTreeFilter {
    border-bottom: 1px solid #ccc;
}